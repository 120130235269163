<div class="wrapper">
    <div class="section header">
        <div class="innerSection">
            <img routerLink="" src="assets/img/logo.png" alt="logo" />
        </div>
    </div>
    <div class="section dark">
        <div class="innerSection">

            <h1>Nutzungsbedingungen für die GIF Online-Luftmengenberechnung (LMB)</h1>
            <h2>1. Registrierungspflicht, Geltung dieser Nutzungsbedingungen</h2>
            Um das Tool zur Online-Luftmengenberechnung (im Folgenden: LMB) nutzen zu können, müssen Sie sich als Nutzer auf unserer Website registrieren. Mit Ihrer Registrierung erklären Sie sich mit der Geltung dieser Nutzungsbedingungen einverstanden, die unter www.luftmengenberecnung.com und www.gif-activevent.com abrufbar sind.

            <h2>2. Berechtigte Nutzer, Kostenfreiheit</h2>

            <p>Diese Nutzungsbedingungen gelten für die Nutzung der LMB unter der domain www.luftmengenberechnung.com</p>
            <p>Die Nutzungsmöglichkeit wird nur gewerblichen Nutzern eingeräumt. Sie ist für diese kostenfrei. Verbraucher sind nicht berechtigt, sich als Nutzer zu registrieren und die LMB zu nutzen.</p>

            <h2>3. Einverständnis mit Datenspeicherung und -nutzung</h2>

            Mit Ihrer Registrierung erklären Sie sich damit einverstanden, dass

            <ol type="a">
                <li>Ihre bei der Registrierung einzugebenden Daten durch uns gespeichert werden (insbesondere Name, Vorname, Unternehmensname, Anschrift des Unternehmens, Telefonnummer des Unternehmens, Mail-Adresse, Passwort)</li>
                <li>wir auf die von Ihnen im LMB erstellten Berechnungen zugreifen</li>
                <li>wir die von Ihnen erfolgten Eingaben für unsere Zwecke auswerten</li>
                <li>wir erfassen, welcher Nutzer die LMB wann und wie oft genutzt hat und wir auch diese Daten für unsere Zwecke auswerten und nutzen</li>
                <li>wir unter Verwendung der aus den durch Sie eingegebenen Daten Kontakt mit dem registrierten Nutzer bzw. dem Unternehmen, für das der Nutzer tätig ist, aufnehmen.</li>
            </ol>

            <p>Sie versichern mit der Registrierung zugleich, dass Sie befugt sind, für sich bzw. das Unternehmen, für das Sie tätig sind, die vorerwähnte Einverständniserklärung abzugeben.</p>
            <p>Sie sind berechtigt, die vorstehenden Einverständniserklärungen jederzeit ganz oder teilweise zu widerrufen, mit der Folge, dass wir die gespeicherten Daten löschen und unsere Nutzungsbefugnis endet.</p>
            <p>Im Falle eines Widerrufs sind wir unsererseits berechtigt, nach Ziff. 6 vorzugehen.</p>

            <h2>4. Keine Haftung für Korrektheit des Berechnungsergebnisses</h2>
            Ihnen als Nutzer ist bekannt, dass die Durchführung einer Luftmengenberechnung ein komplexer Vorgang ist. Um eine korrekte und zuverlässige Luftmengenberechnung zu erreichen, muss sichergestellt sein, dass sämtliche verwendeten Parameter ordnungsgemäß berücksichtigt werden.

            <ol type="a">
                <li>Wir überprüfen die von Ihnen als Nutzer eingegebenen Daten und das Ergebnis der durch Sie durchgeführten Luftmengenberechnung nicht. Wir können deshalb auch die Korrektheit der über das LMB durchgeführten Berechnung nicht gewährleisten. Das LMB dient daher nur zur Erstellung von Musterberechnungen und macht eine gründliche, fachmännische Prüfung der erforderlichen Luftmengen vor Nutzung der Ergebnisse nicht entbehrlich. GIF haftet vor diesem Hintergrund nicht für die Korrektheit der durch das LMB ausgewiesenen Ergebnisse der Luftmengenberechnungen.</li>
                <li>Wenn und soweit Sie die Premium-Variante des LMB nutzen, haben Sie die Möglichkeit, uns Ihre Berechnungen zur kursorischen Prüfung zu übermitteln. Basis für unsere Überprüfung sind jeweils die von Ihnen als Nutzer eingegebenen Daten und die uns zur Verfügung gestellten Informationen. Deren Korrektheit und Vollständigkeit überprüfen wir nicht. Auch bei Verwendung der Premium-Variante des LMB übernimmt GIF daher keine Haftung für die Korrektheit der Ergebnisse. Die Verwendung der Ergebnisse erfolgt auf eigene Gefahr des Nutzers.</li>
            </ol>

            <h2>
                5. Haftung, Haftungsbegrenzung
            </h2>
            <p>Ungeachtet der vorstehenden Ziffer 4 haften wir bei Pflichtverletzungen uneingeschränkt für Vorsatz und grobe Fahrlässigkeit unsererseits sowie unserer gesetzlichen Vertreter und Erfüllungsgehilfen sowie für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer vorsätzlichen oder fahrlässigen Pflichtverletzung unsererseits oder eines unseres gesetzlichen Vertreters oder Erfüllungsgehilfen beruhen. Ebenso uneingeschränkt haften wir bei der Abgabe von Garantien und Zusicherungen, falls gerade ein davon umfasster Mangel unsere Haftung auslöst. Keine Beschränkung unserer Haftung besteht auch bei der Haftung aus Gefährdungstatbeständen, insbesondere nach dem Produkthaftungsgesetz.</p>
            <p>Bei der sonstigen schuldhaften Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) ist unsere verbleibende Haftung auf den vertragstypischen vorhersehbaren Schaden begrenzt. Kardinalpflichten sind wesentliche Vertragspflichten, also solche Pflichten, die dem Vertrag sein Gepräge geben und auf die der Vertragspartner vertrauen darf; es handelt sich um die wesentlichen Rechte und Pflichten, die die Voraussetzung für die Vertragserfüllung schaffen und für die Erreichung des Vertragszwecks unentbehrlich sind.</p>
            <p>Im Übrigen ist unsere Haftung – gleich aus welchem Rechtsgrund, insbesondere Ansprüche aus der Verletzung von vertraglichen Haupt- und Nebenpflichten, unerlaubter Handlung sowie sonstiger deliktischer Haftung – ausgeschlossen.</p>
            <p>Ein Ausschluss oder eine Begrenzung unserer Haftung wirkt auch für unsere gesetzlichen Vertreter oder Erfüllungsgehilfen.</p>
            <p>Mit keiner der voranstehenden Klausel ist eine Änderung der gesetzlichen oder richterlichen Beweislastverteilung bezweckt.</p>

            <h2>6. Recht zur Verweigerung der Registrierung und zur Sperrung des Zugangs</h2>
            Wir behalten uns das Recht vor, Nutzern die Registrierung für die Nutzung des LBM zu verweigern oder aber den Zugang eines bereits registrierten Nutzers zeitweise oder dauerhaft zu sperren.

        </div>
    </div>
    <app-footer></app-footer>
</div>
import { Component, Injector, Input } from '@angular/core';
import { Project } from 'src/app/models/project.model';
import { PrivatePage } from 'src/app/pages/base/privatePage';
import { PDFService } from 'src/app/services/pdf.service';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.less']
})
export class MainLayoutComponent extends PrivatePage {


    constructor(private injector: Injector) {
        super(injector)
    }


    onClickLogout() {
        this.server.logout()
        this.router.navigate([""])
    }

    @Input() project: Project = new Project()

}


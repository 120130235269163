import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export enum ActionType {
    Ok,
    Yes,
    No
}
export interface DialogMessageData {
    title: string
    message: string
    actions?: DialogMessageDataAction[]
}

export interface DialogMessageDataAction {
    text?: string
    type: ActionType
    color: "primary" | "warn"
}

@Component({
    selector: 'app-dialog-message',
    templateUrl: './dialog-message.component.html',
    styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessageComponent {
    action = ActionType

    constructor(public dialogRef: MatDialogRef<DialogMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogMessageData, private translate: TranslateService) {

        if (!data.actions) {
            data.actions = [{
                color: "primary",
                type: ActionType.Ok
            }]
        }

        data.actions.forEach(action => {
            if (action.text) return

            if (action.type == ActionType.No) {
                action.text = this.translate.instant("button.no")
            }
            if (action.type == ActionType.Yes) {
                action.text = this.translate.instant("button.yes")
            }
            if (action.type == ActionType.Ok) {
                action.text = this.translate.instant("button.ok")
            }
        })
    }

    onClickAction(type: ActionType) {
        this.dialogRef.close(type);
    }
}

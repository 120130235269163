import { Component, Injector, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { PrivatePage } from '../base/privatePage';

@Component({
    selector: 'app-page-profil',
    templateUrl: './page-profil.component.html',
    styleUrls: ['./page-profil.component.scss']
})
export class PageProfilComponent extends PrivatePage implements OnInit {

    user?: User
    password1: string = ""
    password2: string = ""

    constructor(private injector: Injector) {
        super(injector)

        this.user = this.server.getCurrentUser()
    }

    ngOnInit(): void {
    }


    async onClickSave() {
        if (!this.user) return

        const oldPassword = this.user.password

        if (this.password1 != "") {
            if (this.password1 != this.password2) {
                this.openErrorMessage("error.password")
                return
            } else {
                this.user.password = this.password1
            }
        } else {
            this.user.password = ""
        }

        const newUser = await this.server.updateUser(this.user)
        if (newUser.error) {
            this.openErrorMessage("error.updateUser", newUser.errorMessage)
            return
        }

        this.password1 = ""
        this.password2 = ""
        newUser.data.password = oldPassword
        this.server.eventLogin.next(newUser.data)
        this.openSuccessInfo("success.save")
    }

}

import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CodedeviceData, CodeService, CodeType } from '../../services/code.service';
import { CustomDataSourceDevice } from '../../services/customDataSource';
import { Project } from '../../models/project.model';
import { SectionKitchenData, SectionType } from '../../models/section.model';
import { DeviceData, SectionService } from '../../services/section.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PrivatePage } from '../base/privatePage';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-page-detail-kitchen',
    templateUrl: './page-detail-kitchen.component.html',
    styleUrls: ['./page-detail-kitchen.component.scss']
})
export class PageDetailKitchen extends PrivatePage implements OnInit {
    sectionID: number | null = null
    section = new SectionKitchenData()
    filteredOptions: Observable<CodedeviceData[]> = new Observable();
    deviceDataSource: CustomDataSourceDevice = new CustomDataSourceDevice([])
    nameFormControl = new FormControl('', [Validators.required]);

    displayedColumns: string[] = ["position", "amount", "description", "kw", "type", "code", "singelSensibel", "singelDampf", "edit", "sumSensibel", "sumDampf", "delete"];

    constructor(public sectionService: SectionService, public codeService: CodeService, private injector: Injector) {
        super(injector)

        this.activeRoute.paramMap.subscribe(paras => {
            void this.load()
        })

        this.nameFormControl.valueChanges.subscribe(value => {
            this.section.name = value || ""
        })
    }

    async load() {
        const projectID = parseInt(this.activeRoute.parent?.snapshot.params["index"])
        this.sectionID = parseInt(this.activeRoute.snapshot.params["section"])

        await this.sectionService.loadDataByProject(projectID)

        const section = this.sectionService.getDataByIndex(SectionType.Kochbereich, this.sectionID)

        if (!section) return

        this.deviceDataSource = this.sectionService.getDataSourceDevice(SectionType.Kochbereich, this.sectionID)
        this.section = <SectionKitchenData>section

        this.nameFormControl.setValue(this.section.name)
    }

    ngOnInit(): void {
        this.loadFilteredOptions("")

        this.nameFormControl.markAsTouched()
    }

    loadFilteredOptions(searchValue: string, type = CodeType.DampfNormal) {
        this.filteredOptions = new Observable(observer => {
            const data = this.codeService.getCodes(type).filter(option => option.text.toLowerCase().includes(searchValue.toLowerCase()) || option.code.toString().includes(searchValue.toLowerCase()))
            observer.next(data)
        })
    }



    onClickAddNewDevice() {
        if (!this.sectionID) return

        this.sectionService.addNewDummyDevice(SectionType.Kochbereich, this.sectionID)
    }
    onCodeChange(event: Event, element: DeviceData) {
        const input = <HTMLInputElement>event.target
        if (!input) return

        this.loadFilteredOptions(input.value, element.type)
    }

    onCodeSelected(code: MatAutocompleteSelectedEvent, deviceData: DeviceData) {
        deviceData.code = (<CodedeviceData>code.option.value).code
        deviceData.codeText = (<CodedeviceData>code.option.value).text
        deviceData.calculateData()
    }

    onChangeKW(deviceData: DeviceData) {
        deviceData.calculateData()
    }

    onClickDeleteDevice(deviceData: DeviceData) {
        if (!this.sectionID) return

        console.log(deviceData)

        this.sectionService.deleteDevice(SectionType.Kochbereich, deviceData, this.sectionID)
    }
    onClickEditSingleValues(deviceData: DeviceData) {
        deviceData.isSingleValueEditActive = !deviceData.isSingleValueEditActive

        if (!deviceData.isSingleValueEditActive) {
            deviceData.calculateData()
        }
    }

    onClickClearCode(deviceData: DeviceData) {
        deviceData.code = 0
        deviceData.codeText = ""
    }

    onClickReset() {
        this.section.lenght = 0
        this.section.depth = 0
        this.section.height = 0
        this.section.z = 1.6
        this.section.gleichzeitigfaktor = 0.8
        this.section.anordung = "frei"
        this.section.abspuelgrad = "1.2"
    }

    async onClickSave() {
        const result = await this.server.updateSection(SectionType.Kochbereich, this.section)
        if (result.error) {
            this.openErrorMessage("error.saveSection", result.errorMessage)
            return
        }

        this.openSuccessInfo("success.save")
    }


    checkIsNumber(event: KeyboardEvent) {
        return event.key != "." && event.key != ","
    }

    formatSliderNumber(value: number): string | number {
        return value.toString().replace(".", ",")
    }
}

<div class="wrapper">
    <div class="innerSection">
        <span>
            Copyright © GIF ActiveVent 2023 | All rights reserved. <a target="_blank" href="https://www.w3code.de">&#64;w3code coded with heart</a>
        </span>
        <div class="links">
            <a target="_blank" routerLink="/term-of-use">{{"navigation.termofuse" | translate}}</a>
            <a href="https://gif-activevent.com/datenschutzerklaerung/" target="_blank">Datenschutz</a>
            <a href="https://gif-activevent.com/impressum/" target="_blank">Impressum</a>
            <a href="https://gif-activevent.com/unternehmen/" target="_blank">Zum Unternehmen</a>
        </div>
    </div>
</div>
<mat-toolbar>
    <div class="logo">
        <img src="assets/img/logo.png" alt="logo" />
    </div>
    <span>Berechnung von Luftmengen gemäß VDI Richtlinie 2052 (Ausgabe April 2017)</span>
    <span class="spacer"></span>
    <button mat-flat-button color="accent" (click)="onClickLogout()">{{ 'login.logout' | translate }}</button>
</mat-toolbar>

<div class="cols">
    <div class="col navigation">
        <app-navigation></app-navigation>
    </div>
    <div class="col content">
        <div class="inner-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<app-footer></app-footer>
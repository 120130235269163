import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageDetailSink } from './pages/page-detail-sink/page-detail-sink.component';
import { PageDetailProject } from './pages/page-detail-project/page-detail-project.component';
import { PageDetailKitchen } from './pages/page-detail-kitchen/page-detail-kitchen.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageRegisterComponent } from './pages/page-register/page-register.component';
import { PageProjectsDashboardComponent } from './pages/page-projects-dashboard/page-projects-dashboard.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PageProfilComponent } from './pages/page-profil/page-profil.component';
import { PageUsersComponent } from './pages/page-users/page-users.component';
import { PageTermsOfUseComponent } from './pages/page-terms-of-use/page-terms-of-use.component';

const routes: Routes = [{
    path: "",
    component: PageLoginComponent
}, {
    path: "register",
    component: PageRegisterComponent
}, {
    component: MainLayoutComponent,
    path: "private",
    children: [{
        path: "projects",
        component: PageProjectsDashboardComponent
    }, {
        path: "projects/new",
        component: PageDetailProject
    }, {
        path: "projects/:index",
        component: PageDetailProject,
        children: [{
            path: "kitchen/:section",
            component: PageDetailKitchen
        }, {
            path: "sink/:section",
            component: PageDetailSink
        }]
    }, {
        path: "users",
        component: PageUsersComponent
    }, {
        path: "users/:userID/projects",
        component: PageProjectsDashboardComponent
    }, {
        path: "users/:userID/projects/:index",
        component: PageDetailProject,
        children: [{
            path: "kitchen/:section",
            component: PageDetailKitchen
        }, {
            path: "sink/:section",
            component: PageDetailSink
        }]
    }, {
        path: "profil",
        component: PageProfilComponent
    },]
}, {
    path: "term-of-use",
    component: PageTermsOfUseComponent
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {

}

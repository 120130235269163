<h1>{{ 'title.profil' | translate }}</h1>

<div *ngIf="user" class="grid">
    <mat-form-field appearance="fill">
        <mat-label>{{"label.salutation" | translate}}</mat-label>
        <mat-select [(ngModel)]="user.salutation">
            <mat-option value="Frau">{{ "label.salutations.mrs" | translate }}</mat-option>
            <mat-option value="Herr">{{ "label.salutations.mr" | translate }}</mat-option>
            <mat-option value="Divers">{{ "label.salutations.divers" | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.firstname" | translate}}</mat-label>
        <input matInput type="string" [(ngModel)]="user.firstname">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.lastname" | translate}}</mat-label>
        <input matInput type="string" [(ngModel)]="user.lastname">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.zip" | translate}}</mat-label>
        <input matInput type="string" [(ngModel)]="user.zipcodeID">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.city" | translate}}</mat-label>
        <input matInput type="string" [(ngModel)]="user.city">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.street" | translate}}</mat-label>
        <input matInput type="string" [(ngModel)]="user.street">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.company" | translate}}</mat-label>
        <input matInput type="string" [(ngModel)]="user.company">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.phone" | translate}}</mat-label>
        <input matInput type="string" [(ngModel)]="user.phone">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.mail" | translate}}</mat-label>
        <input matInput type="string" [(ngModel)]="user.email">
    </mat-form-field>

    <p>{{"info.changePassword" | translate}}</p>
    <p></p>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.password" | translate}}</mat-label>
        <input matInput type="password" [(ngModel)]="password1">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{"label.password" | translate}}</mat-label>
        <input matInput type="password" [(ngModel)]="password2">
    </mat-form-field>
</div>

<div class="buttons">
    <button mat-flat-button color="primary" (click)="onClickSave()">Aktualisieren</button>
</div>
import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { CodeService, CodeSinkData, SinkType } from '../../services/code.service';
import { CustomDataSourceDevice } from '../../services/customDataSource';
import { Project } from '../../models/project.model';
import { SectionData, SectionSinkData, SectionType } from '../../models/section.model';
import { DeviceData, SectionService } from '../../services/section.service';
import { PrivatePage } from '../base/privatePage';
import { ActionType } from 'src/app/components/dialogs/dialog-message/dialog-message.component';

@Component({
    selector: 'app-page-detail-sink',
    templateUrl: './page-detail-sink.component.html',
    styleUrls: ['./page-detail-sink.component.scss']
})
export class PageDetailSink extends PrivatePage implements OnInit {
    stepper: MatStepper | undefined

    @ViewChild('stepper')
    set setStepper(stepper: MatStepper) {
        if (!stepper) return

        this.stepper = stepper
        this.setBiggestStepFocus()
    }

    sectionID: number | null = null
    section = new SectionSinkData()
    codeInput = new FormControl<string>("");
    //filteredOptions: Observable<CodeSinkData[]> = new Observable();
    deviceDataSource: CustomDataSourceDevice = new CustomDataSourceDevice([])

    displayedColumns: string[] = ["position", "code", "description", "wrg", "abluftstutzen", "zuluftstrom", "abluftstromAnteilig", "abluftstromErforderlich", "delete"];

    codeFilterType: SinkType = SinkType.none
    codeFilterNormteller: number | undefined
    filterdCodes: CodeSinkData[] = []
    showCalculation = false
    nameFormControl = new FormControl('', [Validators.required]);

    constructor(public sectionService: SectionService, public route: ActivatedRoute, public codeService: CodeService, private formBuilder: FormBuilder, private injector: Injector) {
        super(injector)

        this.activeRoute.paramMap.subscribe(paras => {
            void this.load()
        })

        this.nameFormControl.valueChanges.subscribe(value => {
            this.section.name = value || ""
        })
    }

    async load() {
        const projectID = parseInt(this.activeRoute.parent?.snapshot.params["index"])
        this.sectionID = parseInt(this.activeRoute.snapshot.params["section"])

        await this.sectionService.loadDataByProject(projectID)

        const section = this.sectionService.getDataByIndex(SectionType.Spülbereich, this.sectionID)
        if (!section) return

        this.deviceDataSource = this.sectionService.getDataSourceDevice(SectionType.Spülbereich, this.sectionID)
        this.section = <SectionSinkData>section
        this.nameFormControl.setValue(this.section.name)
    }

    ngOnInit(): void {
        this.filterCodes()

        this.nameFormControl.markAsTouched()
    }

    filterCodes() {
        let data = this.codeService.getSinkCodes()

        if (this.codeFilterType != SinkType.none) {
            data = data.filter(option => option.maschinentyp == this.codeFilterType)
        }


        const normteller = this.codeFilterNormteller
        if (normteller && normteller > 0) {
            data = data.filter(option => option.normtellerleistung >= normteller)
        }

        this.filterdCodes = data
    }



    onClickAddNewDevice() {
        if (!this.sectionID) return

        this.sectionService.addNewDummyDevice(SectionType.Spülbereich, this.sectionID)
    }


    // onCodeChange(deviceData: DeviceData) {
    //   deviceData.calculateDataSink()
    // }

    onClickResetStep1() {
        this.section.kennwertNonogramm = 0
        this.section.kuechenGrundflaeche = 0
    }
    onClickResetStep3() {
        this.section.faktorAbkuehlverhalten = ""
        this.section.faktorWaermeabgabe = ""
        this.section.amoutSpuelgut = 0

        this.section.waermebelastungSpuelgut = 0
    }
    onClickResetStep3_2() {
        this.section.waermebelastungMaschine = 0
        this.section.waermebelastungSpuelgut = 0
        this.section.austrittsTemperatur = 28
        this.section.eintrittsTemperatur = 20
        this.showCalculation = false

        this.onClickResetStep3()
    }


    async onClickDeleteSink(deviceData: DeviceData) {
        if (!this.sectionID) return

        await this.sectionService.deleteDevice(SectionType.Spülbereich, deviceData, this.sectionID)
        deviceData.calculateDataSink()
        this.section.calcSumSinkData()
    }

    onChangeCode(deviceData: DeviceData) {
        deviceData.calculateDataSink()
        this.section.calcSumSinkData()
    }


    checkIsNumber(event: KeyboardEvent) {
        return event.key != "." && event.key != ","
    }

    async onClickSave() {
        const result = await this.server.updateSection(SectionType.Spülbereich, this.section)
        if (result.error) {
            this.openErrorMessage("error.saveSection", result.errorMessage)
            return
        }

        this.openSuccessInfo("success.save")
    }


    onChangeTemperature() {
        if (this.section.eintrittsTemperatur < 18) {
            this.section.eintrittsTemperatur = 18
            this.openErrorMessage("error.minEintrittsTemperatur")
        }

        if (this.section.austrittsTemperatur <= this.section.eintrittsTemperatur) {
            this.section.austrittsTemperatur = this.section.eintrittsTemperatur + 1
            this.openErrorMessage("error.minAustrittsTemperatur")
        }

        const diffTemparature = this.section.austrittsTemperatur - this.section.eintrittsTemperatur
        if (diffTemparature != 8) {
            this.openQuestionMessage("question.checkTemperature").afterClosed().subscribe(action => {
                if (action == ActionType.No) {
                    this.section.eintrittsTemperatur = 20
                    this.section.austrittsTemperatur = 28
                }
            })
        }
    }

    setBiggestStepFocus() {
        if (!this.stepper) return

        const step = this.section.getBiggestStep()

        this.stepper.selectedIndex = step - 1
    }

    isMultipleSinkStepsFilled() {
        const step1a = this.section.getStep1Abluft()
        const step2a = this.section.sumStep2Abluftstrom
        const step3a = this.section.getStep3Abluft()

        if (step1a > 0 && step2a > 0) return true
        if (step1a > 0 && step3a > 0) return true
        if (step2a > 0 && step3a > 0) return true

        return false
    }

}

import { Injectable } from "@angular/core";
import { IDocument } from "../models/document.model";
import { CustomDataSource, DocumentDataSource } from "./customDataSource";
import { ServerService } from "./server.service";


@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    private documents: IDocument[] = []
    private dataSourceDocuments = new DocumentDataSource(this.documents)

    constructor(private server: ServerService) {

    }

    setData(data: IDocument[]) {
        this.documents = data
        this.dataSourceDocuments.setData(this.documents)
    }
    addData(data: IDocument) {
        this.documents.push(data)
        this.dataSourceDocuments.setData(this.documents)
    }
    removeData(data: IDocument) {
        this.setData(this.documents.filter(doc => doc.ID != data.ID))
    }
    getDataSource() {
        return this.dataSourceDocuments
    }
    getCount() {
        return this.documents.length
    }

    async create(projectID: number, file: File) {
        const base64 = await this.getBase64(file)
        const result = await this.server.createDocument({
            ID: 0,
            name: file.name,
            projectID: projectID,
            data: base64
        })

        if (result.error) return result

        this.addData(result.data)

        return result
    }

    async delete(document: IDocument) {
        const result = await this.server.deleteDocument(document.ID)
        if (result.error) return result

        this.removeData(document)

        return result
    }


    getBase64(file: File) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function () {
                console.log(reader.result)
                resolve(<string>reader.result)
            }
            reader.onerror = function (error) {
                reject(error);
            }
        })
    }
}

<h1>
    {{ 'title.users' | translate }}
</h1>

<mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
</mat-form-field>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.firstname" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.firstname}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.lastname" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.lastname}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.company" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.company}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.area" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.zipcode.areaID}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="ProjectCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.ProjectCount" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.ProjectCount}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="openProjects">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.open" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.openProjects}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="inworkProjects">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.inWork" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.inworkProjects}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="doneProjects">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.done" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.doneProjects}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="incompleteProjects">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.incomplete2" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.incompleteProjects}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"label.role" | translate}}</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline">
                <mat-select [(ngModel)]="element.role" (valueChange)="onChangeRole($event, element)">
                    <mat-option value="admin">{{"label.admin" | translate}}</mat-option>
                    <mat-option value="user">{{"label.user" | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="onClickOpenProjects(element)">
                <mat-icon>search</mat-icon>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
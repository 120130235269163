import { Component, Injector, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { W3codeBase } from '../base/w3codeBase';

@Component({
    selector: 'app-page-register',
    templateUrl: './page-register.component.html',
    styleUrls: ['./page-register.component.scss']
})
export class PageRegisterComponent extends W3codeBase implements OnInit {
    password1: string = ""
    password2: string = ""
    termofuse: boolean = false
    user: User = {
        city: "",
        company: "",
        email: "",
        salutation: "Herr",
        firstname: "",
        lastname: "",
        password: "",
        phone: "",
        zipcodeID: "",
        street: "",
        role: undefined,
        ID: -1
    }

    constructor(private injector: Injector) {
        super(injector)
    }

    ngOnInit(): void {
    }


    async onClickRegister() {
        if (!this.validateEmail(this.user.email)) return this.openErrorMessage("error.validate.mail")
        if (this.password1 == "" || this.password2 == "" || this.user.city == "" || this.user.company == "" || this.user.email == "" || this.user.firstname == "" || this.user.lastname == "" || this.user.phone == "" || this.user.zipcodeID == "" || this.user.street == "") return this.openErrorMessage("Füllen Sie bitte alle Felder aus")
        if (this.password1 != this.password2) return this.openErrorMessage("Die Passwörter stimmen nicht überein")
        if (!this.password1.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{10,}$/)) return this.openErrorMessage("Das Passwort entspricht nicht den Sicherheitsrichtlinie. Mindestens 10 Zeichen, Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen.")
        if (!this.termofuse) return this.openErrorMessage("error.termofuse")

        this.user.password = this.password1
        const result = await this.server.register(this.user)
        if (result.error) return this.openErrorMessage("error.register")

        const dialog = this.openSuccessMessage("Sie haben sich erfolgreich registriert.")
        dialog.afterClosed().subscribe(() => {
            this.router.navigate([""])
        })
        return
    }

    validateEmail(email: string) {
        return email.toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
}

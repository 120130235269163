<div class="footerNav">
    <button mat-raised-button color="primary" routerLink="../../">
        <mat-icon fontIcon="arrow_back"></mat-icon>Zurück zur Übersicht
    </button>
    <button mat-raised-button color="primary" (click)="onClickAddNewSection(true)">
        <mat-icon fontIcon="add"></mat-icon>Neuen Kochbereich einfügen
    </button>
    <button mat-raised-button color="primary" (click)="onClickAddNewSection(false)">
        <mat-icon fontIcon="add"></mat-icon>Neuen Spülbereich einfügen
    </button>
    <button mat-raised-button color="primary" (click)="onClickSave()">
        <mat-icon fontIcon="save"></mat-icon>Speichern
    </button>
</div>
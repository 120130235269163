import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CodeService, CodeType } from './code.service';
import { CustomDataSource, CustomDataSourceDevice } from './customDataSource';
import { SectionData, SectionKitchenData, SectionSinkData, SectionType } from '../models/section.model';
import { ServerService } from './server.service';
import { fetchMessage } from '../models/messages.model';
import { Project } from '../models/project.model';


export interface IDeviceData {
    ID: number
    position: string
    amount: number
    description: string
    kw: number | undefined
    type: CodeType
    code: number | undefined
    codeText: string
    singelSensibel: number
    singelDampf: number
    sumSensibel: number
    sumDampf: number
    isSingleValueEditActive: boolean
    maschinenType: string
    wrg: boolean
    abluftstutzen: boolean
    normtellerleistung: number
    zuluftstrom: number
    abluftstromAnteilig: number
    abluftstromErforderlich: number
    codeSingleValueManuelyChanged: boolean
    sinkID?: number
    kitchenID?: number
}
export class DeviceData extends EventTarget implements IDeviceData {
    public ID: number = -1
    public position: string = ""
    public amount: number = 1
    public description: string = ""
    public kw: number | undefined = undefined
    public type: CodeType = CodeType.ElectroNormal
    public code: number | undefined = 0
    public codeText: string = ""
    public singelSensibel: number = 0
    public singelDampf: number = 0
    public sumSensibel: number = 0
    public sumDampf: number = 0
    public isSingleValueEditActive = false
    public maschinenType = ""
    public wrg = false
    public abluftstutzen = false
    public normtellerleistung = 0
    public zuluftstrom = 0
    public abluftstromAnteilig = 0
    public abluftstromErforderlich = 0

    public codeSingleValueManuelyChanged = false
    public sinkID?: number
    public kitchenID?: number
    private codeService = new CodeService()

    public eventDataChange = new Event("dataChange")

    constructor() {
        super()
    }

    loadData(data: DeviceData) {
        this.ID = data.ID
        this.position = data.position
        this.amount = data.amount
        this.description = data.description
        this.kw = data.kw
        this.type = data.type
        this.code = data.code
        if (data.code) {
            this.codeText = data.codeText == "" || !data.codeText ? data.code.toString() : data.codeText
        }
        this.singelSensibel = data.singelSensibel
        this.singelDampf = data.singelDampf
        this.sumDampf = data.sumDampf
        this.sumSensibel = data.sumSensibel
        this.maschinenType = data.maschinenType
        this.wrg = data.wrg
        this.abluftstutzen = data.abluftstutzen
        this.normtellerleistung = data.normtellerleistung
        this.zuluftstrom = data.zuluftstrom
        this.abluftstromAnteilig = data.abluftstromAnteilig
        this.abluftstromErforderlich = data.abluftstromErforderlich
        this.codeSingleValueManuelyChanged = data.codeSingleValueManuelyChanged
        this.isSingleValueEditActive = data.isSingleValueEditActive
    }

    public calculateData() {
        const codeData = this.codeService.getCodeData(this.code, this.type)

        if (!codeData) return

        if (!this.isSingleValueEditActive) {
            this.singelDampf = codeData.dampf
            this.singelSensibel = codeData.sensibel
        }

        const kw = this.kw ? this.kw : 0

        this.sumDampf = this.singelDampf * this.amount * kw
        this.sumSensibel = this.singelSensibel * this.amount * kw

        this.dispatchEvent(this.eventDataChange)
    }

    public calculateDataSink() {
        const codeData = this.codeService.getSinkCodeData(this.code)

        if (!codeData) return

        this.abluftstromAnteilig = codeData.abluftstromAnteilig
        this.abluftstromErforderlich = codeData.abluftstromErforderlich
        this.abluftstutzen = codeData.abluftstutzen
        this.code = codeData.code
        this.maschinenType = codeData.maschinentyp
        this.normtellerleistung = codeData.normtellerleistung
        this.wrg = codeData.wgr
        this.zuluftstrom = codeData.zuluftstrom

        this.dispatchEvent(this.eventDataChange)
    }

    public isSingleValueChangedManually() {
        const codeData = this.codeService.getCodeData(this.code, this.type)

        if (!codeData) return false

        return codeData.dampf != this.singelDampf || codeData.sensibel != this.singelSensibel
    }
}


@Injectable({
    providedIn: 'root'
})
export class SectionService {

    private sectionsData: SectionData[] = []
    private dataSourceKitchen = new CustomDataSource(this.sectionsData)
    private dataSourceSink = new CustomDataSource(this.sectionsData)
    private dataSourceDevice = new CustomDataSourceDevice([])
    private currentIndex = 0
    private currentLoadedProjectID = -1

    constructor(private router: Router, private server: ServerService) {

        this.sectionsData = []

        this.refreshDataSource()
    }

    loadData(projectID: number, data: SectionData[]) {
        this.sectionsData = data
        this.currentLoadedProjectID = projectID
        this.refreshDataSource()
    }

    async loadDataByProject(projectID: number) {
        const data = this.getAllData()
        if (projectID == this.currentLoadedProjectID && this.getAllData().length > 0) return this.server.getSuccessMessage(data)


        const project = await this.server.loadProject(projectID)
        if (project.error) {
            return this.server.getErrorMessage(project.errorMessage)
        }

        const currentProject = new Project(project.data)
        this.loadData(projectID, [...currentProject.kitchens, ...currentProject.sinks])

        return this.getAllData()
    }

    private refreshDataSource() {
        this.dataSourceKitchen.setData(this.getKitchenData())
        this.dataSourceSink.setData(this.getSinkData())
    }

    getAllData(type: SectionType | undefined = undefined) {
        if (type == SectionType.Kochbereich) {
            return this.getKitchenData()
        } else if (type == SectionType.Spülbereich) {
            return this.getSinkData()
        } else {
            return this.sectionsData
        }
    }

    async createNewSection(type: SectionType, forUserID?: number) {
        let data: SectionData

        if (type == SectionType.Kochbereich) {
            data = new SectionKitchenData()
        } else {
            data = new SectionSinkData()
        }

        const result = await this.server.createSection(this.currentLoadedProjectID, type, data)
        if (result.error) {
            return this.server.getErrorMessage<boolean>(result.errorMessage)
        }

        data.loadData(result.data)
        this.add(data)

        const user = forUserID ? "users/" + forUserID : ""

        this.router.navigate(["private/" + user + "/projects/" + this.currentLoadedProjectID + "/" + type + "/" + result.data.ID])
        return this.server.getSuccessMessage(true)
    }

    private getSinkData() {
        return <SectionSinkData[]>this.sectionsData.filter(val => val.type == SectionType.Spülbereich)
    }
    private getKitchenData() {
        return <SectionKitchenData[]>this.sectionsData.filter(val => val.type == SectionType.Kochbereich)
    }

    getDataByIndex(type: SectionType, sectionID: number) {
        this.currentIndex = sectionID

        return this.sectionsData.find(val => val.ID == sectionID && val.type == type)
    }

    getDataSourceKitchen() {
        return this.dataSourceKitchen
    }
    getDataSourceSink() {
        return this.dataSourceSink
    }

    getDataSourceDevice(type: SectionType, index: number) {
        this.currentIndex = index

        const data = this.getDataByIndex(type, index)
        let finalData: DeviceData[] = []

        if (data) {
            finalData = data.getDevices()
        }

        this.dataSourceDevice = new CustomDataSourceDevice(finalData)
        return this.dataSourceDevice
    }

    getTotalVolumeAbl(type: SectionType) {
        const data = this.sectionsData.filter(val => val.type == type)
        if (data.length == 0) return 0

        return this.sectionsData.filter(val => val.type == type).map(val => val.calcSectionResult().abluft).reduce((prev, curr) => prev + curr)
    }

    getTotalVolumeZul(type: SectionType) {
        const data = this.sectionsData.filter(val => val.type == type)
        if (data.length == 0) return 0

        return data.map(val => val.calcSectionResult().zuluft).reduce((prev, curr) => prev + curr)
    }

    add(section: SectionData) {
        this.sectionsData.push(section)
        this.refreshDataSource()
    }

    async addNewDummyDevice(type: SectionType, sectionID: number) {
        const data = this.getDataByIndex(type, sectionID)

        if (!data) return

        const newDevice = new DeviceData()
        if (type == SectionType.Kochbereich) {
            newDevice.kitchenID = data.ID
        } else {
            newDevice.sinkID = data.ID
        }
        const result = await this.server.createDevice(newDevice)
        if (!result.error) {
            newDevice.ID = result.data.ID
            data.addDevice(newDevice)
            this.dataSourceDevice.setData(data.getDevices())
            this.refreshDataSource()
        }
    }

    async deleteData(section: SectionData, projectID: number) {
        const result = await this.server.deleteSection(projectID, section.type, section.ID)
        if (result.error) return result

        const index = this.sectionsData.findIndex(value => value.ID == section.ID)
        this.sectionsData.splice(index, 1)
        this.refreshDataSource()

        return {
            error: false,
            errorMessage: "",
            data: {}
        } as fetchMessage<unknown>
    }

    async deleteDevice(type: SectionType, deviceData: DeviceData, sectionID: number) {
        const data = this.getDataByIndex(type, sectionID)

        if (!data) return

        const result = await this.server.deleteDevice(deviceData.ID)
        if (!result.error) {
            data.deleteDevice(deviceData)
            this.dataSourceDevice.setData(data.getDevices())
            this.refreshDataSource()
        }

        return result
    }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SectionType } from '../../models/section.model';
import { SectionService } from '../../services/section.service';

@Component({
    selector: 'app-footer-navigation',
    templateUrl: './footer-navigation.component.html',
    styleUrls: ['./footer-navigation.component.scss']
})
export class FooterNavigationComponent implements OnInit {

    @Output()
    save = new EventEmitter();

    constructor(public sectionService: SectionService) { }

    ngOnInit(): void {

    }


    onClickAddNewSection(kitchen: boolean) {
        this.sectionService.createNewSection(kitchen ? SectionType.Kochbereich : SectionType.Spülbereich)
    }

    onClickSave() {
        this.save.emit()
    }

}

import { DataSource } from "@angular/cdk/collections";
import { Observable, ReplaySubject } from "rxjs";
import { SectionData } from "../models/section.model";
import { DeviceData } from "./section.service";
import { IDocument } from "../models/document.model";


export class CustomDataSource extends DataSource<SectionData> {
    private dataStream = new ReplaySubject<SectionData[]>();

    constructor(initialData: SectionData[]) {
        super();
        this.setData(initialData);
    }

    connect(): Observable<SectionData[]> {
        return this.dataStream;
    }

    disconnect() { }

    setData(data: SectionData[]) {
        this.dataStream.next(data);
    }
}


export class CustomDataSourceDevice extends DataSource<DeviceData> {
    private dataStream = new ReplaySubject<DeviceData[]>();

    constructor(initialData: DeviceData[]) {
        super();
        this.setData(initialData);
    }

    connect(): Observable<DeviceData[]> {
        return this.dataStream;
    }

    disconnect() { }

    setData(data: DeviceData[]) {
        this.dataStream.next(data);
    }
}


export class DocumentDataSource extends DataSource<IDocument> {
    private dataStream = new ReplaySubject<IDocument[]>();

    constructor(initialData: IDocument[]) {
        super();
        this.setData(initialData);
    }

    connect(): Observable<IDocument[]> {
        return this.dataStream;
    }

    disconnect() { }

    setData(data: IDocument[]) {
        this.dataStream.next(data);
    }
}


import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-text-with-help',
    templateUrl: './text-with-help.component.html',
    styleUrls: ['./text-with-help.component.scss']
})
export class TextWithHelpComponent implements OnInit {
    @Input()
    text: string | null = ""

    @Input()
    number: number | null = null

    @Input()
    helpText: string = ""

    constructor() { }

    ngOnInit(): void {
    }

}

import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PrivatePage } from '../base/privatePage';
import { IUserOverview, User, UserRole } from 'src/app/models/user.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
    selector: 'app-page-users',
    templateUrl: './page-users.component.html',
    styleUrls: ['./page-users.component.scss']
})
export class PageUsersComponent extends PrivatePage implements OnInit, OnDestroy {
    dataSource = new MatTableDataSource<IUserOverview>([])
    displayedColumns = ["firstname", "lastname", "company", "area", "ProjectCount", "openProjects", "inworkProjects", "doneProjects", "incompleteProjects", "role", "details"]

    @ViewChild(MatSort) sort: MatSort | undefined;

    constructor(private injector: Injector) {
        super(injector)

        this.load()
    }
    ngOnDestroy(): void {

    }
    ngOnInit(): void {
    }

    async load() {
        this.dataSource = await this.server.getUsersAsDatasource()
        if (this.sort) {
            this.dataSource.sort = this.sort
        }
    }


    onClickOpenProjects(user: IUserOverview) {
        this.userService.setOtherUser(user)
        this.router.navigate(["private/users/" + user.ID + "/projects"])
    }

    async onChangeRole(newRole: UserRole, user: IUserOverview) {
        const user1: User = <User><unknown>user
        user1.role = newRole

        const editedUser = await this.server.updateUser(user1, user.ID)

        if (editedUser.error) {
            this.openErrorMessage("error.updateUser", editedUser.errorMessage)
            return
        }

        this.openSuccessInfo("success.save")
    }

    applyFilter(e: Event) {
        const target = <HTMLInputElement>e.target
        this.dataSource.filter = target.value.trim().toLowerCase();
    }
}

import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { PrivatePage } from '../base/privatePage';
import { GIFControlStatus, IProject, ProjectDataSource } from 'src/app/models/project.model';
import { ActionType } from 'src/app/components/dialogs/dialog-message/dialog-message.component';
import { PDFService } from 'src/app/services/pdf.service';
import { ServerService } from 'src/app/services/server.service';
import { IUserOverview } from 'src/app/models/user.model';

@Component({
    selector: 'app-page-projects-dashboard',
    templateUrl: './page-projects-dashboard.component.html',
    styleUrls: ['./page-projects-dashboard.component.scss']
})
export class PageProjectsDashboardComponent extends PrivatePage implements OnInit, OnDestroy {
    admin = false
    dataSource = new ProjectDataSource([])
    displayedColumns = ["projectNumber", "projectName", "city", "date", "controlGIF", "print", "edit", "delete"]
    eventLogedIn
    showMyProjects = true
    forUserID: number | undefined
    otherUser: IUserOverview | undefined

    constructor(private injector: Injector) {
        super(injector)

        this.forUserID = this.activeRoute.snapshot.params["userID"]
        this.load(this.forUserID)
        this.eventLogedIn = this.server.eventLogin.subscribe(() => {
            this.admin = this.server.isUserAdmin()
        })
        this.admin = this.server.isUserAdmin()
    }

    ngOnInit(): void {
        this.otherUser = this.userService.getOtherUser()
    }
    ngOnDestroy(): void {
        this.eventLogedIn.unsubscribe()
    }


    onClickAddNewProject() {
        this.router.navigate(["private/projects/new"])
    }
    onClickDeleteProject(project: IProject) {
        this.openQuestionMessage("question.deleteProject").afterClosed().subscribe(async (action) => {
            if (action == ActionType.No) return

            const result = await this.server.deleteProject(project.ID)
            if (result.error) return this.openErrorMessage("error.deleteProject", result.errorMessage)

            this.dataSource.delete(project.ID)
            return
        })
    }

    onClickPrint(project: IProject) {
        const pdf = new PDFService(project, this.injector)
        pdf.generatePage()
    }


    async load(userID?: number) {
        this.dataSource = await this.server.getProjectsAsDatasource({
            fromUser: userID
        })
    }

    async onClickSearchProjects() {
        this.dataSource = await this.server.getProjectsAsDatasource({
            fromUser: this.showMyProjects ? undefined : -1
        })
    }

    getGIFControlStatus(project: IProject) {
        if (!project.controlGIF) return "Nein"
        if (project.controlGIFResult == GIFControlStatus.open || project.controlGIFResult == GIFControlStatus.inWork) return "Ja"

        return "Erledigt"
    }
}

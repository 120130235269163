import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageDetailProject } from './pages/page-detail-project/page-detail-project.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import localeDE from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { DialogYesNoComponent } from './components/dialogs/dialog-yes-no/dialog-yes-no.component';
import { DialogMessageComponent } from "./components/dialogs/dialog-message/dialog-message.component";
import { PageDetailKitchen } from './pages/page-detail-kitchen/page-detail-kitchen.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSliderModule } from "@angular/material/slider";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PageDetailSink } from './pages/page-detail-sink/page-detail-sink.component';
import { MatStepperModule } from "@angular/material/stepper";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { FooterNavigationComponent } from './components/footer-navigation/footer-navigation.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageRegisterComponent } from './pages/page-register/page-register.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeDeExtra from '@angular/common/locales/extra/de';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PageProjectsDashboardComponent } from './pages/page-projects-dashboard/page-projects-dashboard.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PageProfilComponent } from './pages/page-profil/page-profil.component';
import { VadProfileComponent } from './components/vad-profile/vad-profile.component';
import { TextWithHelpComponent } from './components/text-with-help/text-with-help.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageUsersComponent } from './pages/page-users/page-users.component';
import { PageTermsOfUseComponent } from './pages/page-terms-of-use/page-terms-of-use.component';
import { MatSortModule } from '@angular/material/sort';

registerLocaleData(localeDE, 'de-DE', localeDeExtra);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        PageDetailProject,
        NavigationComponent,
        DialogYesNoComponent,
        DialogMessageComponent,
        PageDetailKitchen,
        PageDetailSink,
        FooterNavigationComponent,
        PageLoginComponent,
        PageRegisterComponent,
        PageProjectsDashboardComponent,
        MainLayoutComponent,
        PageProfilComponent,
        VadProfileComponent,
        TextWithHelpComponent,
        FooterComponent,
        PageTermsOfUseComponent,
        PageUsersComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        CdkAccordionModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatSelectModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatListModule,
        MatTooltipModule,
        MatSliderModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatStepperModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Inject, Injector } from "@angular/core"
import { W3codeBase } from "src/app/pages/base/w3codeBase";



export class PrivatePage extends W3codeBase {

    constructor(@Inject(Injector) injector: Injector) {
        super(injector)


        if (!this.server.isUserLogedIn()) {
            this.router.navigate([""])
            return
        }
    }

}

<div class="wrapper">
    <div class="section header">
        <div class="innerSection">
            <img routerLink="" src="assets/img/logo.png" alt="logo" />
        </div>
    </div>
    <div class="section dark">
        <div class="innerSection">
            <h1>{{"title.register" | translate}}</h1>
            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>{{ "label.salutation" | translate }}*</mat-label>
                    <mat-select [(ngModel)]="user.salutation">
                        <mat-option value="Frau">{{ "label.salutations.mrs" | translate }}</mat-option>
                        <mat-option value="Herr">{{ "label.salutations.mr" | translate }}</mat-option>
                        <mat-option value="Divers">{{ "label.salutations.divers" | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>Vorname*</mat-label>
                    <input matInput type="string" [(ngModel)]="user.firstname">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Nachname*</mat-label>
                    <input matInput type="string" [(ngModel)]="user.lastname">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>Unternehmen*</mat-label>
                    <input matInput type="string" [(ngModel)]="user.company">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>PLZ*</mat-label>
                    <input matInput type="string" [(ngModel)]="user.zipcodeID">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>Ort*</mat-label>
                    <input matInput type="string" [(ngModel)]="user.city">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Straße + Hausnummer*</mat-label>
                    <input matInput type="string" [(ngModel)]="user.street">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>E-Mail*</mat-label>
                    <input matInput type="string" [(ngModel)]="user.email">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Telefonnummer*</mat-label>
                    <input matInput type="string" [(ngModel)]="user.phone">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>Passwort*</mat-label>
                    <input matInput type="password" [(ngModel)]="password1">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Passwort*</mat-label>
                    <input matInput type="password" [(ngModel)]="password2">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-checkbox required="true" [(ngModel)]="termofuse">{{"label.termofuse" | translate}}</mat-checkbox>
                <a target="_blank" routerLink="/term-of-use">
                    <button mat-flat-button color="primary">{{"navigation.termofuse" | translate}}</button>
                </a>
            </div>

            <div class="buttons">
                <button mat-flat-button color="primary" (click)="onClickRegister()">{{"title.register" | translate}}</button>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
import { Injectable } from "@angular/core";
import { IUserOverview } from "../models/user.model";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    otherUser: IUserOverview | undefined

    setOtherUser(user: IUserOverview) {
        this.otherUser = user
    }

    getOtherUser() {
        return this.otherUser
    }

}

import { Component, OnInit, Injector, OnDestroy, HostListener } from '@angular/core';
import { DialogYesNoComponent } from '../../components/dialogs/dialog-yes-no/dialog-yes-no.component';
import { IProject, Project } from '../../models/project.model';
import { SectionData, SectionKitchenData, SectionSinkData, SectionType } from '../../models/section.model';
import { SectionService } from '../../services/section.service';
import { PrivatePage } from '../base/privatePage';
import { of, switchMap } from 'rxjs';
import { PDFService } from 'src/app/services/pdf.service';
import { IDocument } from 'src/app/models/document.model';
import { fetchMessage } from 'src/app/models/messages.model';


@Component({
    selector: 'app-page-detail-project',
    templateUrl: './page-detail-project.component.html',
    styleUrls: ['./page-detail-project.component.scss']
})
export class PageDetailProject extends PrivatePage implements OnInit, OnDestroy {
    admin = false
    currentProjectID: undefined | number
    currentProject = new Project()
    isSectionOpen = false
    forUserID: number | undefined
    eventLogedIn

    constructor(public sectionServive: SectionService, private injector: Injector) {
        super(injector)

        this.router.events.pipe(switchMap(() => of(this.activeRoute.children.length > 0))).subscribe(children => {
            this.isSectionOpen = children
        })

        this.eventLogedIn = this.server.eventLogin.subscribe(() => {
            this.admin = this.server.isUserAdmin()
        })
        this.admin = this.server.isUserAdmin()
    }

    dataSource = this.sectionServive.getDataSourceKitchen()
    dataSourceSink = this.sectionServive.getDataSourceSink()
    dataSourceDocuments = this.documentService.getDataSource()

    displayedColumns: string[] = ["type", "index", 'name', "volumeAbl", "edit", "delete"];
    displayedColumnsSink: string[] = ["type", "index", 'name', 'volumeZul', "volumeAbl", "edit", "delete"];
    displayedColumnsResult: string[] = ["spacer", "volumeAbl", "edit", "delete"];
    displayedColumnsDocuments: string[] = ["name", "show", "delete"];


    ngOnInit(): void {
        this.currentProjectID = this.activeRoute.snapshot.params["index"]
        this.forUserID = this.activeRoute.snapshot.params["userID"]
        void this.load()
    }
    ngOnDestroy(): void {
        this.eventLogedIn.unsubscribe()
    }

    async load() {
        if (!this.currentProjectID) return

        const project = await this.server.loadProject(this.currentProjectID, this.forUserID)
        if (project.error) {
            this.openErrorMessage("error.loadingProject", project.errorMessage)
            return
        }

        this.currentProject = new Project(project.data)

        this.sectionServive.loadData(this.currentProject.ID, [...this.currentProject.kitchens, ...this.currentProject.sinks])
        this.dataSource.setData(this.currentProject.kitchens)
        this.dataSourceSink.setData(this.currentProject.sinks)
        this.documentService.setData(this.currentProject.documents)
    }

    @HostListener('window:beforeunload', ['$event'])
    async beforeunload(e: any) {
        // e.preventDefault();

        // if (this.currentProject.hasChanges()) {
        //     e.preventDefault();
        //     console.log("hat Änderungen")
        // } else {
        //     console.log("Keine Änderungen")
        // }
    }

    getTotalVolumeAbl(kitchen: boolean) {
        return this.sectionServive.getTotalVolumeAbl(kitchen ? SectionType.Kochbereich : SectionType.Spülbereich)
    }

    getTotalVolumeZul(kitchen: boolean) {
        return this.sectionServive.getTotalVolumeZul(kitchen ? SectionType.Kochbereich : SectionType.Spülbereich)
    }

    async onClickAddNewSection(kitchen: boolean) {
        this.sectionServive.createNewSection(kitchen ? SectionType.Kochbereich : SectionType.Spülbereich, this.forUserID)
    }

    onClickDeleteSection(sectionToDelete: SectionData) {

        const dialogRef = this.dialog.open(DialogYesNoComponent, {
            data: {
                text: `Wollen Sie den Bereich "${sectionToDelete.name}" wirklich löschen?`
            }
        })

        dialogRef.afterClosed().subscribe(async (result) => {
            if (!result) return

            const resultDelete = await this.sectionServive.deleteData(sectionToDelete, this.currentProject.ID)
            if (resultDelete.error) {
                this.openErrorMessage("error.delete" + sectionToDelete.type, resultDelete.errorMessage)
            }
        })
    }

    async onClickNewProject() {
        const result = await this.server.createProject(this.currentProject)
        if (result.error) {
            this.openErrorMessage("error.saveProject", result.errorMessage)
        } else {
            this.router.navigate(["private/projects/" + (<IProject>result.data).ID])
        }
    }
    async onClickEditProject() {
        const result = await this.server.updateProject(this.currentProject)
        if (result.error) {
            this.openErrorMessage("error.saveProject", result.errorMessage)
        } else {
            this.openSuccessInfo("success.saveProject")
        }
    }

    onClickPrint() {
        const pdf = new PDFService(this.currentProject, this.injector)
        void pdf.generatePage()
    }

    onClickControlGif() {
        if (this.currentProject.documents.length == 0) {
            this.openErrorMessage("error.documentMiss")
            return
        }

        const user = this.server.getCurrentUser()
        const vad = this.server.getVAD()

        if (!user || !vad) {
            this.openErrorMessage("error.user")
            return
        }

        this.currentProject.controlGIF = true
        this.onClickEditProject()

        this.server.sendMail({
            subject: "Ihr Wunsch zur Kontrolle der Luftmengenberechnung für " + this.currentProject.projectName + " " + this.currentProject.city,
            to: user.email + ";" + vad.mail,
            replyTo: vad.mail,
            text: `${this.getMailSaluation(user)},<br>
                    <br>
                    vielen Dank für die Zusendung Ihrer eigens erstellten Luftmengenberechnung. Wunschgemäß werden wir Ihre Luftmengenberechnung innerhalb der nächsten 10 Werktage (Mo-Fr) kontrollieren und uns wieder bei Ihnen melden.<br>
                    <br>
                    Bitte stellen Sie sicher, dass die hochgeladenen Unterlagen auch wirklich alle notwendigen Informationen enthalten. D.h.
                    <ul>
                        <li>eine Kücheneinrichtungsliste mit Anschlusswerten in kW/h</li>
                        <li>Anschlussart der Küchengeräte (Elektro-, Gas- oder Dampfbeheizt)</li>
                        <li>die Standorte der Küchengeräte (wand- oder freistehend)</li>
                        <li>Maße der Küchengeräte</li>
                    </ul>
                    Vielen Dank.<br>
                    <br>
                    Mit freundlichen Grüßen<br>
                    ${vad.name}<br>
                    <br>
                    GIF ActiveVent GmbH<br>
                    Brühlstraße 7<br>
                    79112 Freiburg<br>
                    <br>
                    T: ${vad.phone}<br>
                    ${vad.mail}<br>
                    www.gif-activevent.com<br>
                    <br>
                    Geschäftsführer: Volker Eckmann, Steffen Wintergerst<br>
                    Amtsgericht Freiburg, HRB 1405 / USt-IdNr. DE142107113`
        })
    }

    onClickAddNewDocument() {
        const input = document.createElement("input")
        const mb10 = 10485760
        input.type = "file"
        input.multiple = true
        input.accept = ".dwg, application/pdf, image/jpeg, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

        input.addEventListener("change", async () => {
            if (!this.currentProjectID) return
            if (!input.files) return
            if (input.files.length + this.documentService.getCount() > 3) {
                this.openErrorMessage("error.maxFileCount")
                return
            }

            const files: Promise<fetchMessage<IDocument>>[] = []

            for (let i = 0; i < input.files.length; i++) {
                const file = input.files[i];
                if (file.size > mb10 * 5) {
                    this.openErrorMessage("error.maxFileSize")
                    return
                }

                files.push(this.documentService.create(this.currentProjectID, file))

            }

            const results = await Promise.all(files)
            const error = results.find((result) => result.error == true)
            if (error) {
                this.openErrorMessage("error.createDocument", error.errorMessage)
            }
        })

        input.click()
    }
    async onClickDeleteDocument(document: IDocument) {
        const dialogRef = this.dialog.open(DialogYesNoComponent, {
            data: {
                text: `Wollen Sie das Dokument "${document.name}" wirklich löschen?`
            }
        })

        dialogRef.afterClosed().subscribe(async (result) => {
            if (!result) return

            const resultDelete = await this.documentService.delete(document)
            if (resultDelete.error) {
                this.openErrorMessage("error.deleteDocument", resultDelete.errorMessage)
            }
        })
    }

    async onClickViewdocument(doc: IDocument) {
        const result = await this.server.getDocument(doc.ID)
        if (result.error || !result.data.data) {
            this.openErrorMessage("error.loadDocument", result.errorMessage)
            return
        }

        const a = document.createElement("a")
        a.href = result.data.data
        a.download = result.data.name
        a.click()
    }

    hasAnySectionManuellyChangedValues() {
        return this.sectionServive.getAllData(SectionType.Kochbereich).findIndex(section => section.hasManuellyChangedValues()) >= 0
    }
}

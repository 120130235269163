import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogYesNoData {
    text: string
}

@Component({
    selector: 'app-dialog-yes-no',
    templateUrl: './dialog-yes-no.component.html',
    styleUrls: ['./dialog-yes-no.component.scss']
})
export class DialogYesNoComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogYesNoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogYesNoData,
    ) { }


    ngOnInit(): void {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

import { Injectable } from '@angular/core';
import { IUserOverview, User, UserLogin } from '../models/user.model';
import { environment } from "../../environments/environment";
import { errorMessage, fetchMessage } from '../models/messages.model';
import { Subject } from 'rxjs';
import { CookieService, Cookies } from './cookie.service';
import { IProject, Project, ProjectDataSource } from '../models/project.model';
import { ISectionData, ISectionKitchenData, SectionData, SectionKitchenData, SectionType } from '../models/section.model';
import { DeviceData, IDeviceData } from './section.service';
import { IDocument } from '../models/document.model';
import { MatTableDataSource } from '@angular/material/table';


@Injectable({
    providedIn: 'root'
})
export class ServerService {
    eventLogin = new Subject<User>()

    private eventLoginObs
    private logedInUser?: User
    private token = ""
    private lastLoadedProjectID = -1

    constructor() {
        const logindata = CookieService.getCookie(Cookies.LoginData)
        if (logindata != "") {
            this.eventLogin.next(JSON.parse(logindata))
            this.logedInUser = <User>JSON.parse(logindata)
            this.token = this.logedInUser.password
            this.checkVAD()
        }

        this.eventLoginObs = this.eventLogin.subscribe((user) => {
            CookieService.setCookie(Cookies.LoginData, JSON.stringify(user))
            this.token = user.password
            this.logedInUser = user
            this.checkVAD()
        })
    }

    async register(user: User): Promise<errorMessage> {
        const response = await fetch(environment.apiPath + "/user", {
            method: "Post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        })

        return this.validateFetch(response)
    }

    async login(user: UserLogin): Promise<fetchMessage<User>> {
        const response = await fetch(environment.apiPath + "/login", {
            method: "Post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        })

        return this.validateFetch<User>(response)
    }

    async checkToken(KDNR: number, token: string): Promise<fetchMessage<boolean>> {
        const response = await fetch(environment.apiPath + "/user/" + KDNR + "/check2fa/" + token, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })

        return response.json()
    }

    private checkVAD() {
        if (!this.logedInUser || !this.logedInUser.zipcode || !this.logedInUser.zipcode.area) return
        if (this.logedInUser.zipcode.area.image == "") {
            this.logedInUser.zipcode.area.image = "profileDummy.jpg"
        }
    }

    async newInitPassword(KDNR: number): Promise<fetchMessage<boolean>> {
        const response = await fetch(environment.apiPath + "/user/" + KDNR + "/newInitPassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })

        return response.json()
    }

    logout() {
        localStorage.removeItem("Zaso-Objekts-" + this.getCustomerID())

        this.logedInUser = undefined
        this.token = ""

        CookieService.resetCookie(Cookies.LoginData)
    }


    async resetPassword(mail: string): Promise<fetchMessage<User>> {
        const response = await fetch(environment.apiPath + `/user/resetpassword`, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                mail: mail
            })
        })

        return this.validateFetch<User>(response)
    }


    isUserLogedIn() {
        return typeof this.logedInUser !== "undefined"
    }
    isUserAdmin() {
        return this.logedInUser?.role == 'admin'
    }

    getCurrentUser() {
        return this.logedInUser
    }

    getCustomerID() {
        return this.logedInUser?.ID
    }


    private async validateFetch<DataType>(response: Response) {
        const data = await response.json().catch(() => {
            return false
        })
        if (!data) return this.getErrorMessage<DataType>("Unbekannter Fehler")

        if (!response.ok) return (<fetchMessage<DataType>>data)

        return this.getSuccessMessage<DataType>(data)
    }

    getSuccessMessage<DataType>(data: DataType): fetchMessage<DataType> {
        return {
            error: false,
            errorMessage: "",
            data: data
        }
    }
    getErrorMessage<DataType>(error: string): fetchMessage<DataType> {
        return {
            error: true,
            errorMessage: error,
            data: <DataType>{}
        }
    }

    getVAD() {
        return this.logedInUser?.zipcode?.area
    }

    async getProjectsAsDatasource(options: ProjectSearchOptions = {}) {
        if (!this.logedInUser) return new ProjectDataSource([])

        if (!options.fromUser) options.fromUser = this.logedInUser.ID
        if (!options.currentUserID) options.currentUserID = this.logedInUser.ID

        const response = await fetch(environment.apiPath + "/project?currentUserID=" + options.currentUserID + "&userID=" + options.fromUser, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return new ProjectDataSource(await response.json())
    }

    async createProject(project: Project) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        if (!project.date) {
            project.date = ""
        }
        project.userID = this.logedInUser.ID
        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/project", {
            method: "Post",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            },
            body: JSON.stringify(project)
        })

        return this.validateFetch<Project>(response)
    }

    async loadProject(projectID: number, userID?: number) {
        if (!this.logedInUser) {
            return this.getErrorMessage<IProject>("Kein User ist eingeloggt")
        }

        if (projectID < 0) {
            return this.getErrorMessage<IProject>("Falsche Projekt ID")
        }
        this.lastLoadedProjectID = projectID

        const response = await fetch(environment.apiPath + "/user/" + (userID || this.logedInUser.ID) + "/project/" + projectID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return this.validateFetch<IProject>(response)
    }

    async deleteProject(projectID: number) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        if (projectID < 0) {
            return this.getErrorMessage("Falsche Projekt ID")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/project/" + projectID, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return this.validateFetch(response)
    }

    async updateProject(project: Project) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        if (!project.date) {
            project.date = ""
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/project/" + project.ID, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            },
            body: JSON.stringify(project)
        })

        return this.validateFetch(response)
    }



    async createSection(projectID: number, type: SectionType, section: SectionData) {
        if (!this.logedInUser) {
            return this.getErrorMessage<ISectionData>("Kein User ist eingeloggt")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/project/" + projectID + "/" + type, {
            method: "Post",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            },
            body: JSON.stringify(section)
        })

        return this.validateFetch<ISectionData>(response)
    }
    async deleteSection(projectID: number, type: SectionType, kitchenID: number) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        if (kitchenID < 0) {
            return this.getErrorMessage("Falsche ID")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/project/" + projectID + "/" + type + "/" + kitchenID, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return this.validateFetch(response)
    }

    async updateSection(type: SectionType, section: SectionData) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        if (section.ID < 0) {
            return this.getErrorMessage("Falsche ID")
        }

        const section2 = <SectionData>JSON.parse(JSON.stringify(section))
        section2.devices = []

        section.devices.forEach((device) => {
            this.updateDevice(device)
        })

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/project/" + this.lastLoadedProjectID + "/" + type + "/" + section.ID, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            },
            body: JSON.stringify(section2)
        })

        return this.validateFetch(response)
    }




    async createDevice(device: IDeviceData) {
        if (!this.logedInUser) {
            return this.getErrorMessage<IDeviceData>("Kein User ist eingeloggt")
        }

        if (!device.sinkID && !device.kitchenID) {
            return this.getErrorMessage<IDeviceData>("Keine Küchen- oder Spülbereich vorhanden")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/device", {
            method: "Post",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            },
            body: JSON.stringify(device)
        })

        return this.validateFetch<IDeviceData>(response)
    }
    async deleteDevice(deviceID: number) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        if (deviceID < 0) {
            return this.getErrorMessage("Falsche ID")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/device/" + deviceID, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return this.validateFetch(response)
    }
    async updateDevice(device: DeviceData) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        if (device.ID < 0) {
            return this.getErrorMessage("Falsche ID")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/device/" + device.ID, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            },
            body: JSON.stringify(device)
        })

        return this.validateFetch(response)
    }

    async loadUsers() {
        if (!this.logedInUser) {
            return this.getErrorMessage<User[]>("Kein User ist eingeloggt")
        }

        const response = await fetch(environment.apiPath + "/user", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return this.validateFetch<User[]>(response)
    }
    async getUsersAsDatasource() {
        if (!this.logedInUser) return new MatTableDataSource<IUserOverview>([])

        const users = await this.loadUsers()
        if (users.error) return new MatTableDataSource<IUserOverview>([])

        return new MatTableDataSource<IUserOverview>(await users.data)
    }

    async updateUser(user: IUserOverview, userIDtoUpdate?: number) {
        if (!this.logedInUser) {
            return this.getErrorMessage<User>("Kein User ist eingeloggt")
        }

        const response = await fetch(environment.apiPath + "/user/" + (userIDtoUpdate || this.logedInUser.ID), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            },
            body: JSON.stringify(user)
        })

        return this.validateFetch<User>(response)
    }

    async createDocument(document: IDocument) {
        if (!this.logedInUser) {
            return this.getErrorMessage<IDocument>("Kein User ist eingeloggt")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/document", {
            method: "Post",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            },
            body: JSON.stringify(document)
        })

        return this.validateFetch<IDocument>(response)
    }

    async deleteDocument(documentID: number) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        if (documentID < 0) {
            return this.getErrorMessage("Falsche ID")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/document/" + documentID, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return this.validateFetch(response)
    }

    async getDocument(documentID: number) {
        if (!this.logedInUser) {
            return this.getErrorMessage<IDocument>("Kein User ist eingeloggt")
        }

        if (documentID < 0) {
            return this.getErrorMessage<IDocument>("Falsche ID")
        }

        const response = await fetch(environment.apiPath + "/user/" + this.logedInUser.ID + "/document/" + documentID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return this.validateFetch<IDocument>(response)
    }

    async sendMail(mail: Mail) {
        if (!this.logedInUser) {
            return this.getErrorMessage("Kein User ist eingeloggt")
        }

        const response = await fetch(environment.apiPath + "/mail", {
            method: "POST",
            body: JSON.stringify(mail),
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.token
            }
        })

        return this.validateFetch(response)
    }
}


export interface ProjectSearchOptions extends BaseOptions {
    fromUser?: number
}

export interface BaseOptions {
    currentUserID?: number
}

export interface Mail {
    to: string
    subject: string
    text: string
    replyTo?: string
}

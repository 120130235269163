import { DeviceData } from "../services/section.service"



export interface ExportData {
    projectName: string
    projectNumber: string
    clerk: string
    date: string | undefined
    plan: string
    drawingnumber: string
    stand: string
    comment: string
    sections: SectionData[]
    city: string
}


export enum SectionType {
    Kochbereich = "kitchen",
    Spülbereich = "sink"
}

export interface SectionResult {
    zuluft: number
    abluft: number
}

export abstract class SectionData implements ISectionData {
    devices: DeviceData[] = []

    abstract calcSectionResult(): SectionResult
    abstract dataChangeEvent(): any
    abstract loadData(data: ISectionData): any

    constructor(public ID: number = 0, public name: string = "", public type: SectionType = SectionType.Kochbereich) {

    }

    addDevice(device: DeviceData) {
        this.devices.push(device)

        device.addEventListener("dataChange", () => this.dataChangeEvent())
    }

    getDevices() {
        return this.devices
    }

    copyAbstractAttributes(data: ISectionData) {
        const newDevices: DeviceData[] = []

        if (data.devices) {
            data.devices.forEach(elemnet => {
                const obj = new DeviceData()
                obj.loadData(elemnet)
                newDevices.push(obj)
            })
        }

        this.devices = newDevices
        this.name = data.name
        this.type = data.type
        this.ID = data.ID
    }

    deleteDevice(deviceData: DeviceData) {
        const index = this.devices.indexOf(deviceData)

        if (index < 0) return

        this.devices.splice(index, 1)
    }

    hasManuellyChangedValues() {
        return this.devices.findIndex(device => device.isSingleValueChangedManually()) >= 0
    }
}

export interface ISectionData {
    ID: number
    name: string
    type: SectionType
    devices: DeviceData[]
}
export interface ISectionKitchenData extends ISectionData {
    lenght: number
    height: number
    depth: number
    z: number
    gleichzeitigfaktor: number
    anordung: "wand" | "frei"
    abspuelgrad: "1.15" | "1.2" | "1.3" | "1.35"
    sumCalcDampf: number
    sumCalcSensible: number
    resultClass: string
}


export class SectionKitchenData extends SectionData implements ISectionKitchenData {

    public lenght = 0
    public height = 0
    public depth = 0

    public z = 1.6
    public gleichzeitigfaktor = 0.8
    public anordung: "wand" | "frei" = "frei"
    public abspuelgrad: "1.15" | "1.2" | "1.3" | "1.35" = "1.2"

    public sumCalcDampf = 0
    public sumCalcSensible = 0
    public resultClass = ""
    private abluftNormal = 0
    private abluftKontrolle = 0

    constructor(index: number = 0, name: string = "") {
        super(index, name, SectionType.Kochbereich)

        this.addDevice(new DeviceData())
    }

    dataChangeEvent() {
        this.calSumDampf()
        this.calSumSemsible()
        this.calcSectionResult()
    }

    loadData(data: ISectionKitchenData) {
        this.copyAbstractAttributes(data)

        this.height = data.height
        this.lenght = data.lenght
        this.depth = data.depth
        this.z = data.z
        this.gleichzeitigfaktor = data.gleichzeitigfaktor
        this.anordung = data.anordung
        this.abspuelgrad = data.abspuelgrad
        this.sumCalcDampf = data.sumCalcDampf
        this.sumCalcSensible = data.sumCalcSensible
        this.resultClass = data.resultClass
    }


    calSumDampf() {
        if (this.devices.length == 0) return

        this.sumCalcDampf = this.devices.map(val => val.sumDampf).reduce((prev, curr) => prev += curr)
    }

    calSumSemsible() {
        if (this.devices.length == 0) return

        this.sumCalcSensible = this.devices.map(val => val.sumSensibel).reduce((prev, curr) => prev += curr)
    }

    calcAbluftNormal() {
        this.calSumSemsible()
        this.calSumDampf()

        const dhydr = this.height == 0 ? 0 : 2 * this.lenght * this.depth * 0.001 / (this.lenght + this.depth)
        const qsk = this.sumCalcSensible * 0.5
        const mindfaktor = this.anordung == 'frei' ? 1 : 0.63
        const vth = 18 * Math.pow(qsk, 1 / 3) * Math.pow(this.z + 1.7 * dhydr, 5 / 3) * mindfaktor * this.gleichzeitigfaktor

        this.abluftNormal = vth * parseFloat(this.abspuelgrad)
        this.checkClass()

        return this.abluftNormal
    }

    calcAbluftKontrolle() {
        this.calSumSemsible()
        this.calSumDampf()

        this.abluftKontrolle = this.sumCalcDampf * this.gleichzeitigfaktor / (6 * 1.2)
        this.checkClass()

        return this.abluftKontrolle
    }

    calcSectionResult() {
        this.abluftNormal = this.calcAbluftNormal()
        this.abluftKontrolle = this.calcAbluftKontrolle()

        this.checkClass()
        return {
            abluft: (this.abluftNormal > this.abluftKontrolle) ? this.abluftNormal : this.abluftKontrolle,
            zuluft: 0
        }
    }


    private checkClass() {
        if (this.abluftNormal == 0 || this.abluftKontrolle == 0) {
            this.resultClass = ""
            return
        }


        if (this.abluftNormal > this.abluftKontrolle) {
            this.resultClass = "isNormal"
        } else {
            this.resultClass = "isControll"
        }
    }
}

export interface ISectionSinkData extends ISectionData {
    kennwertNonogramm: number
    kuechenGrundflaeche: number
    sumStep2Zuluftstrom: number
    sumStep2Abluftstrom: number
    faktorWaermeabgabe: string
    faktorAbkuehlverhalten: string
    amoutSpuelgut: number
    waermebelastungMaschine: number
    waermebelastungSpuelgut: number
    WAERME_KAPAZITAET: number
    LUFTDICHTE: number
    austrittsTemperatur: number
    eintrittsTemperatur: number
}
export class SectionSinkData extends SectionData implements ISectionSinkData {
    public kennwertNonogramm = 0
    public kuechenGrundflaeche = 0
    public sumStep2Zuluftstrom = 0
    public sumStep2Abluftstrom = 0
    public faktorWaermeabgabe = ""
    public faktorAbkuehlverhalten = ""
    public amoutSpuelgut = 0
    public waermebelastungMaschine = 0
    public waermebelastungSpuelgut = 0
    public WAERME_KAPAZITAET = 1.005
    public LUFTDICHTE = 1.2
    public austrittsTemperatur = 28
    public eintrittsTemperatur = 20



    constructor(index: number = 0, name: string = "") {
        super(index, name, SectionType.Spülbereich)
    }

    dataChangeEvent() {
        this.calcSumSinkData()
    }

    loadData(data: ISectionSinkData) {
        this.copyAbstractAttributes(data)

        this.kennwertNonogramm = data.kennwertNonogramm
        this.kuechenGrundflaeche = data.kuechenGrundflaeche
        this.sumStep2Abluftstrom = data.sumStep2Abluftstrom
        this.sumStep2Zuluftstrom = data.sumStep2Zuluftstrom
        this.faktorAbkuehlverhalten = data.faktorAbkuehlverhalten
        this.faktorWaermeabgabe = data.faktorWaermeabgabe
        this.amoutSpuelgut = data.amoutSpuelgut
        this.waermebelastungMaschine = data.waermebelastungMaschine
        this.waermebelastungSpuelgut = data.waermebelastungSpuelgut
        this.austrittsTemperatur = data.austrittsTemperatur
        this.eintrittsTemperatur = data.eintrittsTemperatur
    }

    calcSumSinkData() {
        if (this.devices.length == 0) {
            this.sumStep2Zuluftstrom = 0
            this.sumStep2Abluftstrom = 0
            return
        }

        this.sumStep2Zuluftstrom = this.devices.map(val => val.zuluftstrom).reduce((prev, curr) => prev += curr)
        this.sumStep2Abluftstrom = this.devices.map(val => val.abluftstromErforderlich).reduce((prev, curr) => prev += curr)
    }


    getStep1Zuluft() {
        return this.getStep1Abluft() / 1.05
    }
    getStep1Abluft() {
        return this.kennwertNonogramm * this.kuechenGrundflaeche
    }
    getStep3Zuluft() {
        if (this.faktorWaermeabgabe != "" && this.faktorAbkuehlverhalten != "") {
            const result = (parseFloat(this.faktorWaermeabgabe) * this.amoutSpuelgut * parseFloat(this.faktorAbkuehlverhalten)) / 3600
            this.waermebelastungSpuelgut = Math.round(result * 100) / 100
        }

        const zuluft = (this.waermebelastungMaschine + this.waermebelastungSpuelgut) * 3600 / (this.WAERME_KAPAZITAET * this.LUFTDICHTE * (this.austrittsTemperatur - this.eintrittsTemperatur))

        return zuluft
    }
    getStep3Abluft() {
        return this.getStep3Zuluft() * 1.05
    }


    calcSectionResult() {
        const step1a = this.getStep1Abluft()
        const step1z = this.getStep1Zuluft()
        const step3a = this.getStep3Abluft()
        const step3z = this.getStep3Zuluft()
        const biggestStep = this.getBiggestStep()

        if (biggestStep == 1) {
            return { abluft: step1a, zuluft: step1z }
        } else if (biggestStep == 2) {
            return { abluft: this.sumStep2Abluftstrom, zuluft: this.sumStep2Zuluftstrom }
        } else {
            return { zuluft: step3z, abluft: step3a }
        }
    }

    getBiggestStep() {
        const step1a = this.getStep1Abluft()
        const step3a = this.getStep3Abluft()

        if (step1a > this.sumStep2Abluftstrom && step1a > step3a) return 1
        if (this.sumStep2Abluftstrom > step1a && this.sumStep2Abluftstrom > step3a) return 2

        return 3
    }
}


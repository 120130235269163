import { Component, OnDestroy, OnInit } from '@angular/core';
import { Area } from 'src/app/models/area.model';
import { ServerService } from 'src/app/services/server.service';

@Component({
    selector: 'app-vad-profile',
    templateUrl: './vad-profile.component.html',
    styleUrls: ['./vad-profile.component.scss']
})
export class VadProfileComponent implements OnInit, OnDestroy {

    vad: Area = this.getDummy()
    logedIn

    constructor(private server: ServerService) {
        this.loadVAD()

        this.logedIn = server.eventLogin.subscribe(user => {
            this.loadVAD()
        })
    }

    loadVAD() {
        const vad = this.server.getVAD()
        this.vad = vad ? vad : this.getDummy()
    }

    ngOnDestroy(): void {
        this.logedIn.unsubscribe()
    }

    ngOnInit(): void {
    }

    getDummy(): Area {
        return {
            ID: "",
            image: "",
            mail: "",
            name: "",
            phone: ""
        }
    }

}
